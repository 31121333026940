$blue-header-gradient: linear-gradient(180deg, rgba(84, 184, 196, 1) 0%, rgba(83, 143, 168, 1) 100%);
$white-background: #fbfbfb;

$red-base: #c30000;
$red-dark: #b52929;
$red-light: #ef9999;
$red-lighter: #ffd1d1;

$white-base: #ffffff;
$white-dark: #d1d1d1;
$white-light: #f1f1f1;
$white-lighter: #fafafa;

$gray-base: #b3b3b3;
$gray-dark: #444444;
$gray-light: #919191;
$gray-lighter: #c1c1c1;

$black-base: #4a4848;
$black-border: #585858;
$black-eborder: #686868;
$black-dark: #000000;
$black-light: #4b5a5a;
$black-lighter: #7b7b7b;

$blue-base: #028688;
$blue-dark: #145356;
$blue-light: #00d8e2;
$blue-lighter: #00f4ff;
$blue-bg: #a5e6f7;

$font-size-8: 8px;
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-17: 17px;
$font-size-18: 18px;
$font-size-1-4em: 1.4em;
$font-size-1-2em: 1.2em;
$font-size-1-3em: 1.3em;
$font-size-2em: 2em;

$font-size: 13.6px;
$error-color: $red-base;
$font-color: $black-base;
$font-light-color: $white-base;
$font-blue-color: $blue-base;

$header-bg: #960001;

$chat-new-msgs-bg: #999999bf;
$chat-initials-color: $blue-base;
$chat-att-initials-color: $red-base;
$chat-att-bg-color: #0386884d;
$chat-bg-color: #4645458c;
$chat-att-color: #464545;
$survey-bg-color: #f0f0f04d;

$chat-box-shadow: 2px 1px 3px 0px $white-dark;
$chat-att-box-shadow: -2px -1px 3px 0px $gray-base;

$msg-confirmation: $white-base;
$msg-att-confirmation: #02ba62;

$textarea-border-color: $white-base;
$textarea-focus-border-color: $blue-base;
$textarea-bg-color: rgba(219, 219, 219, 0.392);
$textarea-color: #656565;
$button-bg-color: #960001;
$button-icon-color: $white-base;
$button-shadow-color: $gray-base;
$button-effect-color: $black-dark;
$button-bg-effect-color: $gray-light;
$border-color: $black-base;
$button-red: #960001;
$button-yellow: #f2a51a;
$button-disabled-red: #fba4a4;
$button-success-bg: #218838;
$button-disabled-green: #5e8f69;
$button-success-bg-hover: #0c6d21;

$dropdown-menu: #797979;
$tab-text-color: #464545;
$tab-bg-color: #aaabaf;
$tab-nbg-color: #616161;
$user-item-bg: #c3c3c399;

$loading-color: $red-base;
$loading-color2: $red-dark;

$login-input: #d4d6d8;
$sidebar-bgs: #6f7779;
$bg-att-chat: #ffffff;
$bg-att-chat_container: #646464;

$input-border: #ced4da;

$success-color: #9ed4a2;
$info-color: #9cc3dc;
$warn-color: #ececbc;

$modal-border: #dad9d9;
$modal-bg: #e1e2e4;
$modal-color: #4f4e4e;
$modal-btn-shadow: #adadad;

$rating-base: #cccccc;
$rating-filled: #9bc3d3;
$rating-hover: #deedf2;
$rating-msg: #448844;
$rating-msg-error: $red-base;

$system-msg-bg: rgba(232, 232, 72, 0.251);

$icon-font: 'santander-icons' !default;
$santander-font: 'santander' !default;
$santander-light-font: 'santander-light' !default;
$font-path: 'assets/fonts' !default;

$std-ic_star: '\e907';
$std-ic_quick_message: '\e900';
$std-ic_snow_actions: '\e904';
$std-ic_help: '\e906';
$std-ic_account_people: '\e901';
$std-ic_account_user: '\e902';
$std-ic_add: '\e903';
$std-ic_alert: '\e905';
$std-ic_arrow_down: '\e908';
$std-ic_arrow_left: '\e909';
$std-ic_arrow_right: '\e90a';
$std-ic_arrow_run_down: '\e90b';
$std-ic_arrow_run_left: '\e90c';
$std-ic_arrow_run_right: '\e90d';
$std-ic_arrow_run_top: '\e90e';
$std-ic_arrow_top: '\e90f';
$std-ic_at: '\e911';
$std-ic_check: '\e93f';
$std-ic_circle_add: '\e94b';
$std-ic_circle_attention_fill: '\e94c';
$std-ic_circle_attention: '\e94d';
$std-ic_circle_block: '\e94e';
$std-ic_circle_check_fill: '\e94f';
$std-ic_circle_check: '\e950';
$std-ic_circle_close_fill: '\e951';
$std-ic_circle_clouse: '\e952';
$std-ic_circle_help_fill: '\e953';
$std-ic_circle_help: '\e954';
$std-ic_circle_information_fill: '\e955';
$std-ic_circle_information: '\e956';
$std-ic_circle_more: '\e957';
$std-ic_circle_remove: '\e958';
$std-ic_circle_update_sync: '\e959';
$std-ic_circle_urgent_fill: '\e95a';
$std-ic_close: '\e960';
$std-ic_copy: '\e96e';
$std-ic_dashboard: '\e96f';
$std-ic_dots_horizontal: '\e979';
$std-ic_dots_vertical: '\e97a';
$std-ic_iframe: '\e9b5';
$std-ic_password_typing: '\e9e8';
$std-ic_qrcode: '\e9f8';
$std-ic_search: '\ea07';
$std-ic_security_block: '\ea08';
$std-ic_security_erro: '\ea09';
$std-ic_security_open: '\ea0a';
$std-ic_security_verified: '\ea0b';
$std-ic_security: '\ea0c';
$std-ic_send: '\ea0e';
$std-ic_settings: '\ea10';
$std-ic_std_logo: '\ea2c';
$std-ic_std_now: '\ea2d';
$std-ic_std_people: '\ea30';
$std-ic_std_right_way: '\ea33';
$std-ic_team_interaction: '\ea43';
$std-ic_team_management: '\ea44';
$std-ic_wifi: '\ea63';

.scrollbar {
  will-change: scroll-position;
  scroll-behavior: smooth;
  overflow: auto;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px $gray-dark;
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: $black-lighter;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: $gray-light;
}
