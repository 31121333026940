/* You can add global styles to this file, and also import other style files */
@import "app/palette.scss";

:root {
  --form-content-height: 142px;
  --chat-content-height: calc(100% - 164px);
  --chat-form-content-height: calc(100vh - 176px);
}

@font-face {
  font-family: $santander-font;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("Open-Sans-Regular"),
    local($santander-font),
    url("#{$font-path}/#{$santander-font}.woff") format("woff2"),
    url("#{$font-path}/#{$santander-font}.woff2") format("woff2"),
    url("#{$font-path}/#{$santander-font}.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/#{$santander-font}.ttf") format("truetype");
}

@font-face {
  font-family: $santander-light-font;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Open Sans Light"), local("Open-Sans-Light"),
    local($santander-light-font),
    url("#{$font-path}/#{$santander-light-font}.woff") format("woff"),
    url("#{$font-path}/#{$santander-light-font}.eot?#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/#{$santander-light-font}.ttf") format("truetype"),
    url("#{$font-path}/#{$santander-light-font}.svg") format("svg");
}

// Icomoon Santander Icons
@font-face {
  font-family: "#{$icon-font}";
  src: url("#{$font-path}/#{$icon-font}.eot?9yv33c");
  src: url("#{$font-path}/#{$icon-font}.eot?9yv33c#iefix")
      format("embedded-opentype"),
    url("#{$font-path}/#{$icon-font}.ttf?9yv33c") format("truetype"),
    url("#{$font-path}/#{$icon-font}.woff?9yv33c") format("woff"),
    url("#{$font-path}/#{$icon-font}.svg?9yv33c##{$icon-font}") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="std-"],
[class*=" std-"] {
  font-family: "#{$icon-font}";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.std-ic_star {
  &:before {
    content: $std-ic_star;
  }
}

.std-ic_quick_message {
  &:before {
    content: $std-ic_quick_message;
  }
}

.std-ic_snow_actions {
  &:before {
    content: $std-ic_snow_actions;
  }
}

.std-ic_help {
  &:before {
    content: $std-ic_help;
  }
}

.std-ic_account_people {
  &:before {
    content: $std-ic_account_people;
  }
}

.std-ic_account_user {
  &:before {
    content: $std-ic_account_user;
  }
}

.std-ic_add {
  &:before {
    content: $std-ic_add;
  }
}

.std-ic_alert {
  &:before {
    content: $std-ic_alert;
  }
}

.std-ic_arrow_down {
  &:before {
    content: $std-ic_arrow_down;
  }
}

.std-ic_arrow_left {
  &:before {
    content: $std-ic_arrow_left;
  }
}

.std-ic_arrow_right {
  &:before {
    content: $std-ic_arrow_right;
  }
}

.notranslate {
  translate: no;
}

.std-ic_arrow_run_down {
  &:before {
    content: $std-ic_arrow_run_down;
  }
}

.std-ic_arrow_run_left {
  &:before {
    content: $std-ic_arrow_run_left;
  }
}

.std-ic_arrow_run_right {
  &:before {
    content: $std-ic_arrow_run_right;
  }
}

.std-ic_arrow_run_top {
  &:before {
    content: $std-ic_arrow_run_top;
  }
}

.std-ic_arrow_top {
  &:before {
    content: $std-ic_arrow_top;
  }
}

.std-ic_at {
  &:before {
    content: $std-ic_at;
  }
}

.std-ic_check {
  &:before {
    content: $std-ic_check;
  }
}

.std-ic_circle_add {
  &:before {
    content: $std-ic_circle_add;
  }
}

.std-ic_circle_attention_fill {
  &:before {
    content: $std-ic_circle_attention_fill;
  }
}

.std-ic_circle_attention {
  &:before {
    content: $std-ic_circle_attention;
  }
}

.std-ic_circle_block {
  &:before {
    content: $std-ic_circle_block;
  }
}

.std-ic_circle_check_fill {
  &:before {
    content: $std-ic_circle_check_fill;
  }
}

.std-ic_circle_check {
  &:before {
    content: $std-ic_circle_check;
  }
}

.std-ic_circle_close_fill {
  &:before {
    content: $std-ic_circle_close_fill;
  }
}

.std-ic_circle_clouse {
  &:before {
    content: $std-ic_circle_clouse;
  }
}

.std-ic_circle_help_fill {
  &:before {
    content: $std-ic_circle_help_fill;
  }
}

.std-ic_circle_help {
  &:before {
    content: $std-ic_circle_help;
  }
}

.std-ic_circle_information_fill {
  &:before {
    content: $std-ic_circle_information_fill;
  }
}

.std-ic_circle_information {
  &:before {
    content: $std-ic_circle_information;
  }
}

.std-ic_circle_more {
  &:before {
    content: $std-ic_circle_more;
  }
}

.std-ic_circle_remove {
  &:before {
    content: $std-ic_circle_remove;
  }
}

.std-ic_circle_update_sync {
  &:before {
    content: $std-ic_circle_update_sync;
  }
}

.std-ic_circle_urgent_fill {
  &:before {
    content: $std-ic_circle_urgent_fill;
  }
}

.std-ic_close {
  &:before {
    content: $std-ic_close;
  }
}

.std-ic_copy {
  &:before {
    content: $std-ic_copy;
  }
}

.std-ic_dashboard {
  &:before {
    content: $std-ic_dashboard;
  }
}

.std-ic_dots_horizontal {
  &:before {
    content: $std-ic_dots_horizontal;
  }
}

.std-ic_dots_vertical {
  &:before {
    content: $std-ic_dots_vertical;
  }
}

.std-ic_iframe {
  &:before {
    content: $std-ic_iframe;
  }
}

.std-ic_password_typing {
  &:before {
    content: $std-ic_password_typing;
  }
}

.std-ic_qrcode {
  &:before {
    content: $std-ic_qrcode;
  }
}

.std-ic_search {
  &:before {
    content: $std-ic_search;
  }
}

.std-ic_security_block {
  &:before {
    content: $std-ic_security_block;
  }
}

.std-ic_security_erro {
  &:before {
    content: $std-ic_security_erro;
  }
}

.std-ic_security_open {
  &:before {
    content: $std-ic_security_open;
  }
}

.std-ic_security_verified {
  &:before {
    content: $std-ic_security_verified;
  }
}

.std-ic_security {
  &:before {
    content: $std-ic_security;
  }
}

.std-ic_send {
  &:before {
    content: $std-ic_send;
  }
}

.std-ic_settings {
  &:before {
    content: $std-ic_settings;
  }
}

.std-ic_std_logo {
  &:before {
    content: $std-ic_std_logo;
  }
}

.std-ic_std_now {
  &:before {
    content: $std-ic_std_now;
  }
}

.std-ic_std_people {
  &:before {
    content: $std-ic_std_people;
  }
}

.std-ic_std_right_way {
  &:before {
    content: $std-ic_std_right_way;
  }
}

.std-ic_team_interaction {
  &:before {
    content: $std-ic_team_interaction;
  }
}

.std-ic_team_management {
  &:before {
    content: $std-ic_team_management;
  }
}

.std-ic_wifi {
  &:before {
    content: $std-ic_wifi;
  }
}

body {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
  font-family: $santander-font, sans-serif;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #ffffff;
  }
}

@keyframes typing {
  0%,
  80%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

.tooltip-inner {
  white-space: pre;
  max-width: 280px;
  text-align: left;
}

.text a {
  color: #464545;
  text-decoration: underline;
}

.text button {
  outline: 0 none;
  background-color: #ececec;
  padding: 5px 18px;
  border-radius: 30px;
  border: none;
  margin: 6px;
  font-weight: bold;
}

.text button:hover {
  background-color: #484848;
  color: #f1f1f1;
}

.color-picker {
  .arrow {
    border: 0 none;
  }

  .cursor {
    border-width: 1px !important;
    border-color: #464544 !important;
    box-shadow: 0px 0px 4px 0px $white-base;
  }
}

@viewport {
  width: device-width;
}

@media only screen and (max-width: 700px) {
  body {
    background-size: cover;
    background-repeat: repeat-y;
  }
}

@media only screen and (max-width: 1170px) {
  .text img {
    width: 100% !important;
    height: unset !important;
  }

  .text button {
    margin: 0px auto 6px;
  }
}

.divImg {
  width: 340px;
  height: 180px;
  overflow: hidden;
}

.modal {
  display: block;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-header {
  padding-bottom: 0.5rem;
  justify-content: space-between;
  display: block;
  font-family: SantanderHeadline, sans-serif;
}

.modal-title {
  flex-grow: 1;
  text-align: left;
  margin-right: 30px;
  padding: 0%;
}

.close-button {
  float: right;
  font-size: 35px;
  cursor: pointer;
}

.modal-content {
  margin: 10% auto;
  padding: 20px;
  width: 50%;
  position: relative;
}

a:hover {
  text-decoration: none;
}

.dss-dropdown {
  span.disabled {
    color: #ccc;
    cursor: default;
  }
}

header .dss-dropdown__value {
  font-size: 18px;
  line-height: 24px;
}

.dss-checkbox__icon-checked {
  display: flex !important;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.center-box {
  justify-content: center;
}

.form-chat .inline-buttons {
  button:hover {
    background-color: transparent !important;
  }
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

button:focus {
  outline: none;
}

@media only screen and (max-width: 1368px) {
  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }

  .accordions-container .dss-accordion__item-title {
    font-size: 16px;
  }
}

.accordions-container {
  .dss-accordion {
    padding: 8px 0;
  }

  .dss-accordion__item {
    border: 1px solid #767676;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 16px;
    padding-left: 16px;
    margin-right: 8px;
    padding-right: 16px;
    background-color: white;
  }

  .dss-accordion__item:last-child {
    margin-bottom: 0;
  }

  .dss-accordion__item-title {
    margin-right: 4px;
  }

  @keyframes dss-accordion-slide-in-down {
    100% {
      padding-top: 0;
    }
  }

  .dss-accordion__item-header:hover {
    background-color: unset;
  }

  .dss-accordion__item .dss-accordion__item-body {
    font-size: 15px;
    color: #444444;
  }
}

.fullscreen-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.highlight-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}

.highlight-element {
  position: relative;
  z-index: 9999;
  box-shadow: 2px solid red;
  background: white;
}

.highlight-element-overlay {
  position: fixed;
  z-index: 9999;
  background: transparent;
  pointer-events: none;
}

.tutorial-button {
  background-color: #c00;
  border: none;
  border-radius: 10px;
  color: #fff;
  height: 48px;
  line-height: 32px;
  max-width: 400px;
  min-width: 120px;
  padding: 8px 16px;
  text-decoration: none;
  transition-property: background-color, transform;
  float: right;
  cursor: pointer;
}

.finish-tutorial-button {
  background-color: #fff;
  border: 1px solid #c00;
  border-radius: 10px;
  color: #c00;
  height: 48px;
  line-height: 30px;
  max-width: 320px;
  min-width: 88px;
  padding: 8px 16px;
  text-decoration: none;
  transition-property: background-color, border-color, color, transform;
}

.tutorial-message-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 10000;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 80%;
  max-width: 520px;
  box-sizing: border-box;
  border: 1px solid #484848;
}

.tutorial-message-box-smart-help {
  position: fixed;
  top: 46%;
  left: 30%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 10000;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 60%;
  max-width: 320px;
  box-sizing: border-box;
  border: 1px solid #484848;
}

.tutorial-message-box-tech-service {
  position: fixed;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 10000;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 60%;
  max-width: 420px;
  box-sizing: border-box;
  border: 1px solid #484848;
}

.tutorial-message-box p {
  text-align: start;
  margin: 0 0 20px;
  font-size: 1.025rem;
}

.tutorial-checkbox-box {
  width: auto;
  align-items: center;
  display: flex;

  #finish-tutorial {
    height: 1rem;
    width: 1rem;
    margin-right: 0.4rem;
    border-color: #900;
  }

  #finish-tutorial:checked {
    accent-color: #c00;
  }

  label {
    cursor: pointer;
    font-family: SantanderMicroText, sans-serif;
    font-size: 0.875rem;
    margin: 0;
  }
}

.tutorial-option-box {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.operator-status .dss-dropdown__select {
  min-height: 32px;
}

.quick-msg-table {
  ul.dss-pagination__list {
    margin-bottom: 0;
  }

  .dss-pagination {
    padding: 16px;
  }
}

#upload-container {
  .dss-list.dss-list--text .dss-list__item {
    padding: 0;
    margin-bottom: 8px;

    &-title {
      display: flex;
      align-items: center;
    }
  }
}

// backoffice global styles
.container-backoffice {
  width: calc(100vw - 32px);
  padding-top: 80px;
  padding-left: 124px;
}

.breadcrumb-backoffice {
  .dss-breadcrumb__item:last-of-type .dss-breadcrumb__link > *,
  .dss-breadcrumb__link > a {
    font-size: 18px;
    line-height: 28px;
    font-family: SantanderText, sans-serif;
  }

  .dss-breadcrumb__title > h1 {
    margin-bottom: 24px;
  }

  .dss-breadcrumb {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1368px) {
    .dss-breadcrumb__title > h1 {
      font-size: 32px;
      line-height: 48px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .dss-menu {
    top: 56px;
    height: calc(100vh - 56px);
  }

  .dss-menu__submenu--expanded {
    top: 56px;

    .dss-menu__submenu-title {
      margin-bottom: 0px;
      padding: 0;
      padding-bottom: 16px;
    }
  }

  .dss-download__wrapper {
    padding-right: 1rem;
  }

  .drawer-img-user {
    .dss-drawer:not(.dss-drawer--aside) .dss-drawer__content {
      height: auto;
      align-self: center;
      min-height: 50px;
      width: auto;
    }
  }
}

@media only screen and (min-width: 1369px) {
  .dss-menu {
    top: 80px;
    height: calc(100vh - 80px);
  }

  .dss-menu__submenu--expanded {
    top: 80px;
  }

  .container-backoffice {
    padding-top: 104px;
  }
}

.form-quick-message .dss-form-field {
  margin-bottom: 24px;
}

.dss-data-table,
.dss-pagination {
  border-radius: 8px;
}

// attendant
.table-quick-message {
  .dss-table-wrapper {
    height: 264px;
    overflow-x: hidden;
  }

  .dss-table tbody td:not(.dss-table__fixed-column) {
    white-space: inherit;
  }
}

// pop-up session
.dss-radio-group {
  display: flex;
}

.dss-radio-group__label-content {
  text-align: left;
}

.dss-radio-group__item {
  padding: 16px;
  padding-left: 0;
  justify-content: center;
}

.dss-radio-group__label {
  width: auto;
}

.notranslate {
  translate: no;
}

.page-401 .dss-result-state {
  width: 80%;
  font-family: SantanderText, sans-serif;

  .dss-result-state__title {
    line-height: 1.36;
    position: relative;
    top: -76px;
  }
}

.header-custom .dss-logo > dss-logo-santander-icon {
  display: flex;
}

.video iframe {
  min-height: 300px;
}

.overlay-connection .dss-content-loader {
  &__content {
    div {
      width: 102px !important;
      height: 82px !important;
    }
  }

  &:not(.dss-content-loader--has-text) {
    min-height: 82px;
  }
}

// nps
@keyframes blink {
  50% {
    color: transparent;
  }
}
.loader__dot {
  animation: 2s blink infinite;
  margin-right: 0.25rem;
  font-weight: 800;
}
.loader__dot:nth-child(2) {
  animation-delay: 650ms;
}
.loader__dot:nth-child(3) {
  animation-delay: 800ms;
  margin-right: 0;
}

.text.nps button:disabled {
  background-color: #ececec !important;
  color: #1010104d !important;
}

.system-link {
  cursor: pointer;
  font-weight: 800;
}
